<template>
    <div id="app" class="home_editor">
        <ckeditor :editor="editor" @ready="onReady" v-model="editorData" :config="editorConfig"></ckeditor>
        <button style="margin-top: 20px;" @click="getContent">获取内容</button>
     </div>
</template>
<script>
import ClassicEditor from '../components/in-editor/core/ckeditor';
import UploadAdapter from '../components/in-editor/core/uploadImageAdapter';
export default {
    data(){
        return {
            editor: ClassicEditor,
            editorData: '<blockquote><p>好好学习，天天向上。</p></blockquote><figure class="image image-style-side"><img src="https://winnorm.oss-cn-chengdu.aliyuncs.com/manage/1620370055169_lawyer_8.jpeg"><figcaption>会议照片</figcaption></figure><p><span style="background-color:rgb(255,255,255);color:rgb(0,0,0);font-size:14px;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 李连杰律师，西南财大法律硕士，四川元诺律师事务所律师。1999年开始从事专职律师工作，主要为公司、企业、事业单位、政府部门提供法律顾问及诉讼服务工作。执业 22 年来，积累了经验，形成了自有特长和风格，擅长风险风控、纠纷化解、商务谈判，赢得客户信赖和肯定。</span></p><p><span style="background-color:rgb(255,255,255);color:rgb(0,0,0);font-size:14px;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 李连杰律师，西南财大法律硕士，四川元诺律师事务所律师。1999年开始从事专职律师工作，主要为公司、企业、事业单位、政府部门提供法律顾问及诉讼服务工作。执业 22 年来，积累了经验，形成了自有特长和风格，擅长风险风控、纠纷化解、商务谈判，赢得客户信赖和肯定。</span></p><p><span style="background-color:rgb(255,255,255);color:rgb(0,0,0);font-size:14px;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 李连杰律师，西南财大法律硕士，四川元诺律师事务律师。1999年开始从事专职律师工作，主要为公司、企业、事业单位、政府部门提供法律顾问及诉讼服务工作。执业 22 年来，积累了经验，形成了自有特长和风格，擅长风险风控、纠纷化解、商务谈判，赢得客户信赖和肯定。</span></p><figure class="image"><img src="https://winnorm.oss-cn-chengdu.aliyuncs.com/manage/1620370055169_lawyer_8.jpeg"><figcaption>参赛的地址</figcaption></figure><p>好好学习，天天向上。</p>',
            editorConfig:{},
            caseCount: 10,
        }
    },
    methods:{
        caseLoadMore(){
            console.log('load =====', this.isLoad)
            this.caseCount += 2
        },
        getContent(){
            console.log(this.editor.content)
            alert(this.editorData)
        },
        onReady(editor){
            console.log('编辑器初始化完成')
            // 照片上传配置
            editor.plugins.get("FileRepository").createUploadAdapter = loader =>{
                return new UploadAdapter(loader)
            }
        }
  },
}
</script>
<style lang="less">
.home_editor{
    width: 1100px;
    margin: 50px auto;
    // margin-top: 50px;
}
</style>